.compare-box {
  position: relative;
  display: block;
  background-color: #f1f2f6;
  border-radius: 4px;
  border: 1px solid #262729 !important;
  width: 190px;
  height: 68px;
  padding: 16px 12px;
  margin-right: 32px;
  overflow: hidden;

  &__inside {
    font-size: 14px;
    color: #333333;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  & .no-plan {
    line-height: 18px;
    justify-content: center;
  }

  .compareRemovePlan {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 16px;
    font-size: 14px;
    color: #000000;
    cursor: pointer;
  }

  &__logo {
    width: 46px;
    align-self: flex-start;
  }

  &__plan-name {
    width: 94px;
    padding-left: 10px;
    height: 52px;
    font-family: "Inter-SemiBold";
    font-size: 13px;
    line-height: 17px;
    color: #333;
    overflow: hidden;
  }

  @media screen and (min-width: 768px) and (max-width: 1177px) {
    width: 140px;
    height: 70px;
    display: inline-block;
    margin-right: 44px;
    padding: 12px 8px;

    &__inside {
      align-items: flex-start;

      &.no-plan {
        align-items: center;
      }
    }

    &__logo {
      width: 32px;
    }

    &__plan-name {
      width: 90px;
      padding-left: 6px;
      height: 54px;
      font-size: 12px;
      line-height: 13px;
    }
  }
}

@media screen and (max-width: 767px) {
  .compare-box__plan-name {
    // position: relative;
    // top: 9px;
    // width: calc(100% - 34px);

    position: relative;
    padding-right: 10px;
    top: 3px;
    width: calc(100% - 34px);

    @media screen and (max-width: 325px) {
      font-size: 12px;
    }
  }
}
