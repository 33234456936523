.blurred-wrapper a {
  text-decoration: none;
  display: inline-block;
}

.blurred-wrapper {
  height: 100vh;
  /* background: url("/assets/images/lp.jpg") center/cover; */
  /* background: #fff; */
  position: relative;
  /* z-index: 1; */
  overflow: hidden;
  /* animation: hue 10s infinite alternate; */
  color: black;
}

.blurred-wrapper::after {
  content: "";
  width: 100%;
  height: 100%;
  background: inherit;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(2rem);
  z-index: -1;
  transform: scale(1.15);
}

@keyframes hue {
  from {
    filter: hue-rotate(0);
  }
  to {
    filter: hue-rotate(360deg);
  }
}
.main-header {
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerLp {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3rem;
}

.logoLp {
  font-size: 2.6rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.btnLp {
  padding: 1rem 2rem;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  background: black;
}

.sign-up {
  color: #fff;
}

.hero {
  display: flex;
  align-items: center;
  height: calc(100% - 12rem);
}

.content-wrapper {
  max-width: 40rem;
  transform: translateY(-4rem);
}

.tagline {
  text-transform: uppercase;
  font-size: 1rem;
  /* font-weight: lighter; */
  margin-bottom: 1rem;
  margin-top: 5rem;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.messageLp {
  font-size: 1.4rem;
  margin-bottom: 3rem;
}

.cta {
  background-color: #000;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .btnLp {
    /* padding: 1rem 3rem; */
  }

  .content-wrapper {
    max-width: 60rem;
  }

  .tagline {
    font-size: 1.6rem;
  }

  .title {
    font-size: 3rem;
  }

  .messageLp {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .messageLp {
    margin-bottom: 15px;
    /* color: #fff !important; */
  }
  /* .blurred-wrapper {
         background: url("/assets/images/insurance2.png");
         background-size: 150px;
         background-repeat: no-repeat;
         background-position: 100% 15%;
     } */
  .title {
    font-size: 2rem !important;
  }
}

@media (max-width: 1170px) {
  .car {
    width: 550px !important;
  }
}
@media (max-width: 1050px) {
  .car {
    width: 450px !important;
  }
}
/* @media(max-width: 1080px) {
     .car {
         width: 650px;
     }
 } */

@media (max-width: 766px) {
  .hero {
    text-align: center !important;
    margin-top: 100px;
    height: auto !important;
  }
  .tagline {
    font-size: 1.1rem !important;
    margin-top: 0 !important;
  }
  .title {
    font-size: 2.5rem !important;
    margin-bottom: 5rem !important;
  }
  .messageLp {
    font-size: 1.5rem !important;
    margin-top: 170px !important;
  }
  .car_button {
    height: 65px !important;
    margin-right: 1rem !important;
  }
  .bike_button {
    height: 65px !important;
    margin-right: 1rem !important;
  }
  .cv_button {
    height: 65px !important;
    margin-right: 1rem !important;
  }
}
@media (max-width: 576px) {
  .hero {
    text-align: center !important;
    margin-top: 100px;
    height: auto !important;
  }
  .tagline {
    font-size: 0.8rem !important;
    margin-top: 0 !important;
  }
  .title {
    font-size: 1.3rem !important;
    margin-bottom: 5rem !important;
  }
  .messageLp {
    font-size: 1rem !important;
    margin-top: 170px !important;
  }
  .car_button {
    height: 65px !important;
    margin-right: 1rem !important;
  }
  .bike_button {
    height: 65px !important;
    margin-right: 1rem !important;
  }
  .cv_button {
    height: 65px !important;
    margin-right: 1rem !important;
  }
}

@media (max-width: 450px) {
  .messageLp {
    margin-top: 100px !important;
  }
}

@media (max-width: 400px) {
  .btnLp {
    font-size: 10px;
  }
  .tagline {
    font-size: 0.8rem;
    margin-top: 0;
  }
  /* .title {
         font-size: 1.3rem !important;
     }
     .messageLp {
         font-size: 1rem;
     }
     .car_button {
         height: 65px !important;
         margin-right: 1rem !important;
     }
     .bike_button {
         height: 65px !important;
         margin-right: 1rem !important;
     }
     .cv_button {
         height: 65px !important;
         margin-right: 1rem !important;
     } */
}
@media (max-height: 600px) {
  .tagline {
    visibility: hidden;
  }
  .btnLp {
    z-index: 1 !important;
  }
  .car {
    width: 600px !important;
  }
}

@media (max-width: 320px) {
  .containerLp {
    padding: 0 15px !important;
  }
  .tagline {
    visibility: visible;
  }
}

@media (max-height: 420px) {
  .btnLp {
    z-index: 1 !important;
  }
  .title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .tagline {
    font-size: 1rem;
    margin-top: 150px;
  }
  .messageLp {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
}

@media (max-height: 380px) {
  .btnLp {
    z-index: 1 !important;
  }
  .title {
    margin-top: -3rem;
  }
  .tagline {
    color: black !important;
  }
  .messageLp {
    margin-top: -5rem !important;
  }
}

@media (max-width: 320px) {
  .content-wrapper {
    transform: translateY(-4rem);
  }
}
