.sendQuotes {
  width: 90%;
  table {
    border-collapse: collapse;
    overflow-y: scroll;
    height: 200px;
    display: block;
    &::-webkit-scrollbar {
      width: 3px;
    }
  }

  td {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .stepwizard {
    display: table;
    width: 100%;
    position: relative;
  }
  .stepwizard-row {
    display: table-row;
  }
  .stepwizard-row:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #ccc;
  }
  .stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
  }

  .stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
  }

  .stepwizard-step p {
    margin-top: 10px;
  }

  .sendQuoteTableHead {
    background: #3a3a3a;
    color: #fff;
  }

  .check-share-quote {
    display: flex;
    justify-content: center;
    margin-right: 20px;
  }
  .cover-sendquote {
    width: 130px;
  }
  .type-sendquote {
    width: 100px;
  }
  .insurer-sendquote {
    width: 100px;
  }
  @media only screen and (max-width: 420px) {
    .cover-sendquote {
      width: 60px;
      max-width: 60px;
    }
    .type-sendquote {
      width: 60px;
      max-width: 60px;
    }
    .insurer-sendquote {
      width: 60px;
      max-width: 60px;
    }
  }
}
