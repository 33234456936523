/* Input Fields */

.form-control:active,
.form-control:focus {
	border-radius: 0;
	border: solid 1px #999;
	box-shadow: none;
	background-color: rgba(246, 246, 246, 0.2);
}

.form-control-sm {
	height: calc(1.5em + 0.5rem + 2px);
	padding: 1rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}

.csip {
	.form-control {
		height: calc(1.5em + 0.5rem + 2px);
		padding: 1rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5;
		// border-radius: 0.2rem;
	}
}

.form-control {
	display: block;
	width: 100%;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #999;
	border-radius: 0;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fname {
	.form-control-sm {
		padding: 0.2rem 0;
		height: 34px;
		-moz-appearance: none;
		@-moz-document url-prefix() {
			-moz-appearance: none !important;
			background: transparent
				url("data:image/gif;base64,R0lGODlhBgAGAKEDAFVVVX9/f9TU1CgmNyH5BAEKAAMALAAAAAAGAAYAAAIODA4hCDKWxlhNvmCnGwUAOw==")
				right center no-repeat !important;
			background-position: calc(100% - 5px) center !important;
		}
	}
}

.fname1 {
	.form-control {
		padding: 1rem 0.5rem;
	}
	.form-control-sm {
		background: transparent right center no-repeat !important;
	}
}

//multi select file-medical-card

.css-yk16xz-control,
.css-yk16xz-control :focus {
	border-radius: 0px !important;
}

//Row Size -proposal parent -file proposal_form
.row-dimension-design {
	@media (max-width: 568px) {
		.col-sm-12 {
			padding-left: 10px !important;
			padding-right: 10px !important;
		}
	}
}

/*element animation*/
.ElemFade {
	-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s; /* Firefox < 16 */
	-ms-animation: fadein 1s; /* Internet Explorer */
	-o-animation: fadein 1s; /* Opera < 12.1 */
	animation: fadein 1s;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.regSplit {
	width: 33.33%;
}

.regGrp {
  display: flex;
}
