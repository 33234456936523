.ratingCont {
	float: left;
	display: block;
	width: 50%;
	padding: 0 0;
	height: 67px;
}

.rating-box {
	height: 31px;
	display: inline-block;
	padding: 0 2px;
}

.rating-box .rating-container input {
	display: none;
}

.rating-box .rating-container label {
	display: inline-block;
	margin: 4px 2px 0;
	color: #d4d4d4;
	cursor: pointer;
	font-size: 19px;
	transition: color 0.2s;
	font-weight: 700;
	margin-right: 6px;
}

.ratingCont:nth-child(2),
.ratingCont:nth-child(1) {
	margin-bottom: 32px;
}

.ratingTitle {
	font-family: "Inter-SemiBold";
	font-size: 16px;
	line-height: 20px;
	color: #333;
}

.rating-box .rating-container {
	direction: rtl !important;
}

.rateTxt {
	font-family: "Inter-Regular";
	font-size: 12px !important;
	line-height: 18px;
	color: #333;

	& span {
		font-size: 12px !important;
	}
}

.rateTxt span {
	margin-left: 74px;
}

.rating-box .rating-container label:hover,
.rating-box .rating-container label:hover ~ label,
.rating-box .rating-container input:checked ~ label {
	color: #28a745;
}
.rating-box .rating-container label:hover,
.rating-box .rating-container label:hover ~ label {
	color: #28a745;
	transform: scale(1.4);
	transition: transform 0.4s;
}

.feedRatingForm {
	padding: 18px 61px 20px;
}
.feedRatingWrap {
	float: left;
	width: 624px;
	box-shadow: 0 8px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);

	//	background-color: rgba(242, 88, 27, 0.05);
	// &:hover {
	// 	transform: scale(1.05);
	// 	transition: transform 0.7s;
	// 	box-shadow: 0 8px 25px -5px #b3ffb3, 0 10px 10px -5px #b3ffb3;
	// }
}

.rateSubmitWrp {
	width: 100%;
	float: left;
	text-align: center;
	margin-top: 40px;
}
.rateSubmitWrp .proceedNextFeedBack {
	width: 220px;
	height: 48px;
	font-size: 14px;
	line-height: 17px;
	font-family: "Inter-SemiBold";
}

.stepper-subHeader,
.stepper-subHeader2,
.stepper-subHeader3,
.stepper-subHeader4 {
	font-family: "Inter-Light";
	color: #333;
	font-size: 20px;
	font-weight: normal;
	margin: 8px 0 24px 0;
	padding: 0 30px;
	text-align: center;
	line-height: 24px;
}

.boldFontHelp {
	font-family: "Inter-SemiBold";
	font-size: 16px;
	margin: 24px 0 0 0;
}

.customWrap {
	width: 666px;
	margin: 0 auto;
	display: table;
}

.feedLightHead {
	font-family: "Inter-Regular";
	font-size: 16px;
	line-height: 22px;
	color: #333;
	margin-top: 6px;
	text-align: center;
}

.feedBack-boldFont {
	font-family: "Inter-SemiBold";
	font-size: 20px;
	margin: 13px 0 0 0;
}

.mainCheckIcon {
	text-align: center;
	margin-top: 16px;
}

.laxIconWrap {
	position: relative;
	text-align: center;
	width: 116px;
	margin: 0 auto;
	z-index: 1;
}

.laxIcon {
	width: 96px;
	background-color: #fff;
	border: solid 0.5px #e3e4e8;
	border-radius: 50%;
}

.feedback-wrap {
	min-width: 300px;
	margin-top: -56px;
	width: 100%;
}

.ratingCont:nth-child(2) .rateTxt span,
.ratingCont:nth-child(3) .rateTxt span {
	margin-left: 91px;
}

.feedRatingSuc {
	padding: 18px 18px 20px;
}

.ratingSucessCont {
	font-size: 14px;
	line-height: 22px;
	font-family: "Inter-Regular";
	color: #333;
}

.ratingSucessCont span:first-child {
	margin-bottom: 8px;
}

.ratingSucessCont span {
	float: left;
}

.visitTxt {
	float: left;
	margin: 36px 0 5px 0;
	font-family: "Inter-SemiBold";
	width: 100%;
	text-align: center;
}

.visitLinks {
	width: 100%;
	float: left;
	text-align: center;
	margin-bottom: 30px;
}

@media only screen and (max-width: 993px) {
	.ratingCont:nth-child(even) {
		padding-left: 0;
	}

	.ratingCont:nth-child(2) .rateTxt,
	.ratingCont:nth-child(3) .rateTxt {
		padding-left: 0;
	}

	.rateTxt span {
		margin-left: 0;
	}

	span {
		font-size: 14px;
	}
	.customWrap {
		width: 100%;
	}

	.stepper-subHeader,
	.stepper-subHeader2,
	.stepper-subHeader3,
	.stepper-subHeader4 {
		font-size: 13px;
		font-family: "Inter-Regular";
		line-height: 18px;
		padding: 0;
		width: 290px;
		margin: 12px auto 8px;
	}

	.feedBack-boldFont {
		width: 100%;
		font-family: "Inter-SemiBold";
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 19px;
	}

	.feedLightHead {
		margin: 0 auto;
		width: 284px;
		font-size: 14px;
		line-height: 20px;
	}

	.laxIcon {
		width: 72px;
	}

	.feedBack-boldFont {
		font-family: "Inter-SemiBold";
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 19px;
	}

	.boldFontHelp {
		font-family: "Inter-SemiBold";
		font-size: 18px;
		line-height: 24px;
		margin: 0 0 11px 0;
		width: 100%;
	}

	.feedRatingForm,
	.feedRatingWrap {
		padding: 20px 15px 24px;
		width: 100%;
		margin: 0;
		margin-bottom: 90px;
	}

	.ratingCont {
		width: 100%;
		margin-bottom: 24px;
		text-align: center;
	}

	.ratingCont:nth-child(2),
	.ratingCont:nth-child(1) {
		margin-bottom: 24px;
	}

	.rateSubmitWrp .proceedNextFeedBack {
		width: 100%;
	}
	.visitLinks {
		margin-bottom: 0;
	}
	.visitLinks div {
		width: 100%;
		margin: 0;
	}
}

.ratingCont:nth-child(2) .rateTxt,
.ratingCont:nth-child(3) .rateTxt {
	padding-left: 6px;
}

.rateTxt span {
	margin-left: 74px;
}

.proceedNextFeedBack {
	height: 54px;
	border-radius: 50px;

	font-family: "Inter-SemiBold";
	font-size: 13px;
	line-height: 28px;
	font-weight: 500;
	outline: none;
	border: none;
	margin: 0 auto;
	text-transform: uppercase;
	padding: 14px 0;
}
.feedbackmainBtn .proceedNextTop {
	font-size: 14px;
	line-height: 17px;
	width: 224px;
	height: 48px;

	margin: 20px;
	border-radius: 50px;
	@media only screen and (max-width: 993px) {
		width: 80%;
		font-size: 12px;
	}
}
.ratingFormWrapper {
	display: flex;
	justify-content: center;
}

.conatinerFeedback {
	width: 50%;
	@media only screen and (max-width: 993px) {
		width: 90%;
	}
}
