.paymentTermRadioWrap {
  float: left;
  width: 100%;
  margin-bottom: 20px !important;
}
.paymentTermRadioWrap .radioCheckedColor {
  border: none !important;
  background: none !important;
}
.paymentTermRadioWrap .panel-heading {
  width: 100%;
  height: 20px !important;
  position: relative;
  padding: 0;
  margin: 0;
}
.IDVRatio {
  box-shadow: none !important;
  border: none !important;
}
.ratioButton {
  display: block;
  position: relative;
  padding-top: 15px !important;
  padding-left: 24px !important;
  margin-bottom: 0 !important;
  height: 44px !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  font-size: 14px;
  font-weight: 400 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radioCheckedColor {
  background-color: #fff !important;
  border-color: #000 !important;
}
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked),
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;
}
.ratioButton input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
input[type="checkbox"],
input[type="radio"] {
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
.ratioButton input:checked ~ .checkmark {
  background-color: #ffffff;
}
.ratioButton input:checked ~ .checkmark {
  background-color: #ffffff;
}
.paymentTermRadioWrap .checkmark {
  top: 1px !important;
  left: 0 !important;
  height: 18px;
  width: 18px;
}
.radioCheckedColor .checkmark {
  border: solid 2px #000;
}
.checkmark {
  position: absolute;
  top: 18px;
  left: 15px;
  height: 22px !important;
  width: 22px !important;
  border: solid 2px #d2d3d4;
  border-radius: 50%;
  bottom: 24px;
}
.paymentTermRadioWrap .radioCheckedColor .checkBoxTextIdv {
  font-family: "Inter-SemiBold";
  color: #000;
}
.paymentTermRadioWrap .checkBoxTextIdv {
  font-size: 14px;
  line-height: 18px;
  color: #333;
  padding-left: 26px;
  text-align: left;
  justify-content: left;
  padding: 0 0 0 0px;
  min-height: 19px;
  position: relative;
  bottom: 11px;
  left: 4px;
}
.checkBoxTextIdv {
  /* display: block; */
  /* font-size: 18px; */
  /* line-height: 18px; */

  display: flex;
  font-size: 16px;
  line-height: 20px;
  min-height: 34px;
  padding: 6px 8px 0;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  color: #333;
}
.idvInput {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid 1px #e3e4e8;
  width: 260px;
  font-size: 16px;
  padding: 0 0 8px 0;
  margin-bottom: 8px;
}
.idvCustmTxt {
  font-size: 10px;
  line-height: 15px;
  color: #333;
}
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked),
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;
}
.paymentTermRadioContent {
  margin-top: 20px;
}
.txtCheckedBold {
  font-family: "Inter-SemiBold";
  color: #000 !important;
}
