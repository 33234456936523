[type="checkbox"]:checked,
[type="checkbox"]:not(:checked),
[type="radio"]:checked,
[type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
	width: 0;
	height: 0;
	visibility: hidden;
}
input[type="checkbox"],
input[type="radio"] {
	margin: 4px 0 0;
	margin-top: 1px\9;
	line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
}
.checkbox-tools:not(:checked) + label {
	background-color: #eaeaea;
	/* box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%); */
	color: #000;
	
	font-family: "Inter-Light";
}
.checkbox-tools:checked + label,
.checkbox-tools:not(:checked) + label {
	position: relative;
	display: inline-block;
	padding: 5px 0;
	color: black !important;
	width: 87px;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 1px;
	margin: 0 auto;
	margin-left: 36px;
	margin-right: -26px;
	margin-bottom: 8px;
	text-align: center;
	border-radius: 4px;
	overflow: hidden;
	cursor: pointer;
	/* text-transform: uppercase; */
	color: var(--white);
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
}
label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 700;
}
.checkbox-tools:checked + label::before,
.checkbox-tools:not(:checked) + label::before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	/* background-image: linear-gradient(298deg, var(--red), var(--yellow)); */

	z-index: -1;
}

.checkbox-tools:checked + label {
	/* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); */
	color: #000;
}
