/* Fonts style ends */

html {
  max-width: 100%;
  overflow-x: hidden;
  height: 100%;
  scroll-behavior: smooth;
}
body {
  position: relative;
  font-family: "basier_squaremedium";
  color: #000000;
  padding-bottom: 80px;
  min-height: 110%;
  margin: 0 auto -50px;
  overflow-x: hidden;
}
iframe {
  pointer-events: none !important;
}

.razorpay-checkout-frame {
  pointer-events: auto !important;
}

#root {
  box-shadow: 0 -19px 19px 2px rgb(219 225 229);
  /* box-shadow: 0px 0px 20px 10px rgb(219 225 229);
  position: relative; */
  min-height: 100%;
}

@media (max-width: 2560px) {
  body {
    padding: 0 16%;
  }
}
@media (max-width: 2400px) {
  body {
    padding: 0 14%;
  }
}
@media (max-width: 2200px) {
  body {
    padding: 0 12%;
  }
}
@media (max-width: 2000px) {
  body {
    padding: 0 10%;
  }
}
@media (max-width: 1900px) {
  body {
    padding: 0 6%;
  }
}
@media (max-width: 1800px) {
  body {
    padding: 0 0;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #cccccc;
}
::-webkit-scrollbar-thumb:hover {
  background-color: grey;
}
