@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
/* loader */
.loader {
  display: none;
  width: 100%;
  text-align: center;
  position: fixed;
  margin: 0 auto;
  z-index: 99999;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
}
.loader img {
  width: 60px;
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 49%;
}

.tooltip.show {
  opacity: 1;
}
.tooltip {
  padding: 6px;
}

/*
  arrow down and up
*/
.arrow {
  font-family: fontawesome;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 26px;
  color: #444;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.up::before {
  content: "\f106";
}

.down::before {
  content: "\f107 ";
}

/* datepicker tranistion */
.react-datepicker-popper {
  transition: all 300ms ease-in-out;
  -moz-transform: scale(0.9) !important;
  z-index: 10 !important;
}

#login-form {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 100vh !important;
}
/* .reviewIconfirmWrap .round-check {
	border: 1px solid #ffff66;
}

.reviewIconfirmWrap .round-check-selected {
	border-color: #bdd400!important;
	border: 1px solid #bdd400;
}

.reviewIconfirmWrap .round-check-selected:checked, .round-label:before {
	border-color: #bdd400!important;
	border: 1px solid #bdd400;
}
.reviewIconfirmWrap .round-check:checked, .round-label:before {
	border-color: #bdd400!important;
	border: 1px solid #bdd400;
}

.round-label:before, .reviewIconfirmWrap .round-label:before {
	border-radius: 50%;
	border-color: #ffff66!important;
	border: 1px solid #ffff66 !important;
} */
/* #ffff66 */

/* label::after {
	content: "";
	border: 2px solid #27ae60;
	width: 24px;
	height: 24px;
	position: absolute;
	left: -42px;
	top: -6px;
	border-radius: 50%;
  } */

.group-check input[type="checkbox"] {
  display: none;
}

.round-label {
  position: relative;
}
.group-check input[type="checkbox"]:checked + label:before {
	content: "";
	background-image: url(../assets/img/check.svg);
	background-position: center;
	background-size: 105%;
	width: 16px;
	height: 16px;

  position: absolute;
  top: 2.5px;

  transform: scale(0);
}

.round-label:before {
	content: "";
	border: 1px solid #d4d5d9;
	/* border: 1px solid #00000087 !important; */
	width: 16px;
	height: 16px;
	position: absolute;
	top: 2.5px;
	cursor: pointer;
	border-radius: 2px;
}
.cashless_row {
  width: 200%;
}

.values {
  border: 0.5px dotted #00000082;
  padding: 2px 0px;
}

.submit_button:hover:enabled {
  background: rgb(189, 212, 0);
  color: #fff;
}

.reset_btn:hover {
  background: red;
  color: #fff;
}

/* multiselect */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-2rem);
  }
}

.menu_country {
  animation: fadeIn 0.18s ease-in-out;
}
.menu_country_close {
  animation: fadeOut 1s ease-in-out;
}

.temp_data_quotes:hover {
  box-shadow: 2px 2px 10px grey !important;
}

.my-form,
.my-form:focus {
  padding: 18px;
  border-radius: 8px;
  border: 1px solid black;
}

@media (max-width: 768px) {
  .review-back-btn {
    top: 340px !important;
  }
  .cashless_row {
    width: 100%;
  }
  .search_div {
    width: 100% !important;
  }
  .cashless_ui {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .cashless_input {
    margin-left: 0px !important;
  }
}

.MuiPaper-root {
  /* background-color: red !important; */
  overflow-x: hidden !important;
}

/* .cIDNkk {
	box-shadow: none !important;
}
.fkAFUL {
	box-shadow: none !important;
} */

/* .filterMenuBoxCheck label:before {
	background-color: #fff;
} */

.cashless_input {
  margin-left: 2%;
}

.multiEmails .form-control {
  border: 2px solid #e3e4e8;
  padding: 11px 18px;
  border-radius: 10px;
}

.multiEmails .form-control input {
  font-size: 18px;
  font-weight: bold;
}

.react-multi-email > input {
  width: 100% !important;
}

/* .multiEmails .form-control:focus, .form-control.focus{
	border: 2px solid black;
	padding: 11px 18px;
	border-radius: 10px;
	box-shadow: none;
} */

.reactMultiEmails {
  border: 2px solid #e3e4e8;
  padding: 6px 10px;
  border-radius: 10px;
  font-size: 16px;
  border-radius: 8px;
}

.react-multi-email.focused {
  border-color: #000;
}

.react-multi-email.empty > span[data-placeholder] {
  color: #3333339c !important;
  font-weight: bold;
}

@media (min-width: 1600px) {
  #mainContainerQuotes {
    width: 1520px !important;
    margin: auto;
  }
}
@media (max-width: 576px) {
  .Toastify__toast {
    top: 135px !important;
    left: 0px !important;
  }
}