.bounce-bottom {
	-webkit-animation: bounce-bottom 0.9s both;
	animation: bounce-bottom 0.9s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-11-23 16:25:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-bottom
 * ----------------------------------------
 */
@-webkit-keyframes bounce-bottom {
	0% {
		-webkit-transform: translateY(45px);
		transform: translateY(45px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 1;
	}
	24% {
		opacity: 1;
	}
	40% {
		-webkit-transform: translateY(24px);
		transform: translateY(24px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	65% {
		-webkit-transform: translateY(12px);
		transform: translateY(12px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	82% {
		-webkit-transform: translateY(6px);
		transform: translateY(6px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	93% {
		-webkit-transform: translateY(4px);
		transform: translateY(4px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	25%,
	55%,
	75%,
	87% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		opacity: 1;
	}
}
@keyframes bounce-bottom {
	0% {
		-webkit-transform: translateY(45px);
		transform: translateY(45px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 1;
	}
	24% {
		opacity: 1;
	}
	40% {
		-webkit-transform: translateY(24px);
		transform: translateY(24px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	65% {
		-webkit-transform: translateY(12px);
		transform: translateY(12px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	82% {
		-webkit-transform: translateY(6px);
		transform: translateY(6px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	93% {
		-webkit-transform: translateY(4px);
		transform: translateY(4px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	25%,
	55%,
	75%,
	87% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		opacity: 1;
	}
}

.slide-out-left {
	-webkit-animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
		both;
	animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-11-23 16:33:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-left {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(-1000px);
		transform: translateX(-1000px);
		opacity: 0;
	}
}
@keyframes slide-out-left {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(-1000px);
		transform: translateX(-1000px);
		opacity: 0;
	}
}
